import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Groups from "views/groups/groups.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Users from "views/users/users.js";
import PrivacyPolicy from "views/privacy policy/privacyPolicy";
import PublicPrivacyPolicy from "views/privacy policy/publicPrivacyPolicy";
import Terms from "views/termsAndCondition/terms";
import Setting from "views/settings/setting";
import PublicTerms from "views/termsAndCondition/publicTerms";
import Notification from "views/notification/notification";
import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-green",
    component: Users,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  {
    path: "/groups",
    name: "Groups",
    icon: "ni ni-folder-17 text-blue",
    component: Groups,
    layout: "/admin",
  },
  {
    path: "/notification",
    name: "Send Notification",
    icon: "ni ni-notification-70 text-green",
    component: Notification,
    layout: "/admin",
  },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/adminprivacypolicy",
    name: "Privacy Policy",
    icon: "ni ni-badge text-blue",
    component: PrivacyPolicy,
    layout: "/admin",
  },

  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    icon: "ni ni-badge text-green",
    component: PublicPrivacyPolicy,
    layout: "/auth",
  },
  {
    path: "/termsandconditions",
    name: "Terms & Conditions",
    icon: "ni ni-badge text-green",
    component: Terms,
    layout: "/admin",
  },
  {
    path: "/termsandcondition",
    name: "Terms & Conditions",
    icon: "ni ni-badge text-blue",
    component: PublicTerms,
    layout: "/auth",
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-blue",
    component: Setting,
    layout: "/admin",
  },
];
export default routes;
