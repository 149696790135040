import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../state/index";
import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import Loader from "react-loader-spinner";
import moment from "moment";

import Header from "components/Headers/Header.js";

const Index = (props) => {
  const dispatch = useDispatch();
  const {
    GET_USERS,
    GET_GROUPS,
    GET_PRIVACY_POLICY,
    GET_TERM_CONDITION,
    GET_WEEKLY_DATA,
    GET_SETTINGS,
  } = bindActionCreators(actionsCreators, dispatch);
  const [activeNav, setActiveNav] = useState(4);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [dateType, setDateType] = useState("day");

  let totalgroups = 1000000;

  const weeklydata = useSelector((state) => state.allAuth.weeklyData);

  const arr = [];
  for (let key in weeklydata?.data) {
    arr.push(weeklydata?.data[key]);
  }

  const graphLoading = useSelector((state) => state.allAuth.graphLoading);

  useEffect(() => {
    GET_TERM_CONDITION();
    GET_PRIVACY_POLICY();
    GET_USERS();
    GET_GROUPS(totalgroups);
    GET_WEEKLY_DATA("day");
    GET_SETTINGS();
  }, []);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index, type) => {
    setDateType(type);
    e.preventDefault();
    GET_WEEKLY_DATA(type);
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  let Chartdata = {
    labels: weeklydata?.data && Object.keys(weeklydata?.data),
    datasets: [
      {
        data: weeklydata?.data && arr,
        maxBarThickness: 10,
      },
    ],
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Overview
                    </h6>
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      In This {dateType}
                    </h6>
                    <h2 className="text-uppercase mb-0">Total Signup</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1, "year")}
                        >
                          {activeNav === 1 ? (
                            <span className="d-none d-md-block">Year</span>
                          ) : (
                            <span
                              className="d-none d-md-block"
                              style={{ color: "#000" }}
                            >
                              Year
                            </span>
                          )}

                          <span className="d-md-none" style={{ color: "#000" }}>
                            Y
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2, "month")}
                        >
                          {activeNav === 2 ? (
                            <span className="d-none d-md-block">Month</span>
                          ) : (
                            <span
                              className="d-none d-md-block"
                              style={{ color: "#000" }}
                            >
                              Month
                            </span>
                          )}

                          <span className="d-md-none" style={{ color: "#000" }}>
                            M
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 3,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 3, "week")}
                        >
                          {activeNav === 3 ? (
                            <span className="d-none d-md-block">Week</span>
                          ) : (
                            <span
                              className="d-none d-md-block"
                              style={{ color: "#000" }}
                            >
                              Week
                            </span>
                          )}

                          <span className="d-md-none" style={{ color: "#000" }}>
                            W
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 4,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 4, "day")}
                        >
                          {activeNav === 4 ? (
                            <span className="d-none d-md-block">Day</span>
                          ) : (
                            <span
                              className="d-none d-md-block"
                              style={{ color: "#000" }}
                            >
                              Day
                            </span>
                          )}

                          <span className="d-md-none" style={{ color: "#000" }}>
                            D
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  {graphLoading ? (
                    <div className="text-center">
                      <Loader
                        type="Grid"
                        color="#8DBF26"
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    <>
                      {weeklydata?.isSuccess ? (
                        <Bar data={Chartdata} />
                      ) : (
                        <h2>
                          Currently data is not avaliable, Please try later
                        </h2>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
