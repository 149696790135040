import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Dropdown,
} from "reactstrap";
// core components

import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../../state/index";
import Loader from "react-loader-spinner";
import moment from "moment";
import GroupExport from "components/helper/groupExport";
import { useToasts } from "react-toast-notifications";
import { SortArray } from "../../components/helper/filter";

const Groups = () => {
  const [arrowToggle, setArrowToggle] = useState(true);
  const [arrowToggleTMember, setArrowToggleTMember] = useState(true);
  const [lnamearrowDate, setLnamearrowDate] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [dataperPage, setDataperPage] = useState(10);
  const [selectedrows, setSelectedRows] = useState([]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [perpage, setPerpage] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [newmodal, setNewModal] = useState(false);
  const [Cdelete, setCdelete] = useState(false);
  const [deletemsg, setDeletemsg] = useState(
    "Are you sure you want to delete this Group?"
  );
  const [rowId, setRowId] = useState("");
  const [betData, setBetData] = useState([]);

  useEffect(() => {
    GET_GROUPS(totalgroups);
  }, []);

  let totalgroups = 1000000;
  const MAX_LENGTH = 50;
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { GET_GROUPS, GET_BET_BY_GROUPID, DELETE_GROUP, FORCE_DELETE_GROUP } =
    bindActionCreators(actionsCreators, dispatch);

  const isloading = useSelector((state) => state.allAuth.headerLoading);
  const Cisloading = useSelector((state) => state.allAuth.isLoading);
  const allGroups = useSelector((state) => state.allAuth.allGroups);

  console.log(allGroups?.data?.list);
  // Toggle for Modal
  const toggle = (row) => {
    setSelectedRows(row);
    setModal(!modal);
  };
  const Togglearrow = (e) => {
    if (arrowToggle) {
      SortArray(allGroups?.data?.list, e, "acs");
      setArrowToggle(!arrowToggle);
    } else {
      SortArray(allGroups?.data?.list, e, "dsc");
      setArrowToggle(!arrowToggle);
    }
  };
  const TogglearrowMember = (e) => {
    if (arrowToggleTMember) {
      SortArray(allGroups?.data?.list, e, "acs");
      setArrowToggleTMember(!arrowToggleTMember);
    } else {
      SortArray(allGroups?.data?.list, e, "dsc");
      setArrowToggleTMember(!arrowToggleTMember);
    }
  };
  const Togglearrowdate = (e) => {
    if (lnamearrowDate) {
      SortArray(allGroups?.data?.list, e, "date");
      setLnamearrowDate(!lnamearrowDate);
    } else {
      SortArray(allGroups?.data?.list, e, "newdate");
      setLnamearrowDate(!lnamearrowDate);
    }
  };
  const deletetoggle = (id) => {
    setRowId(id);
    setdeletemodal(!deletemodal);
  };
  async function newtoggle(row) {
    setNewModal(!newmodal);
    try {
      const betData = await GET_BET_BY_GROUPID(row);
      if (betData.data.isSuccess) {
        setBetData(betData?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const changePage = (pageNumber) => {
    setcurrentPage(pageNumber);
  };
  const filteredPersons = allGroups?.data?.list.filter((person) => {
    return person.title.toLowerCase().includes(searchField.toLowerCase());
  });
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const deleteGroup = async () => {
    try {
      const deleteres = await DELETE_GROUP(rowId);
      if (deleteres?.data?.isSuccess) {
        addToast(deleteres?.data?.message, {
          appearance: "success",
          autoDismiss: true,
        });
        GET_GROUPS(totalgroups);
        setdeletemodal(!deletemodal);
      } else {
        if (deleteres?.data?.data?.inBetProgress) {
          setDeletemsg(deleteres?.data?.message);
          setCdelete(true);
        }
      }
    } catch (error) {
      addToast(error?.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const forcedeleteGroup = async () => {
    try {
      const fdeleteres = await FORCE_DELETE_GROUP(rowId);
      if (fdeleteres?.data?.isSuccess) {
        addToast(fdeleteres?.data?.message, {
          appearance: "success",
          autoDismiss: true,
        });
        GET_GROUPS(totalgroups);
        setdeletemodal(!deletemodal);
        setDeletemsg("Are you sure you want to delete this Group?");
        setCdelete(false);
      } else {
        addToast(fdeleteres?.data?.message, {
          appearance: "erroe",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(error?.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const indexOfLastData = currentPage * dataperPage;
  const indexOfFirstData = indexOfLastData - dataperPage;
  const currentPages = filteredPersons?.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const pagesNumbers = [];

  for (
    let i = 1;
    i <= Math.ceil(allGroups?.data?.list?.length / dataperPage);
    i++
  ) {
    pagesNumbers.push(i);
  }

  console.log(selectedrows, "selectedrows");
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          {selectedrows?.members?.length === 0 ? (
            <div>
              <h1>No Members Found</h1>
            </div>
          ) : (
            <div>
              {selectedrows?.members?.reverse().map((row) => (
                <div className="container">
                  <div className="row">
                    <div className="underline mt-2 col-md-6">
                      {row.id !== selectedrows?.admin?.id ? (
                        <b>Member</b>
                      ) : (
                        <b>Admin</b>
                      )}
                      <div className="mt-2">
                        <p>
                          <b>FullName</b> : {row.firstName} {row.lastName}
                        </p>
                        <p>
                          <b>Created On Date</b> :<br />
                          {moment(new Date(row.createdOnDate)).format(
                            "MM-DD-YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="underline mt-2 col-md-6">
                      <b>&nbsp;</b>
                      <div className="mt-2">
                        <p>
                          <b>Won</b> :{" "}
                          {selectedrows.membersObj[row.id].won !== null
                            ? selectedrows.membersObj[row.id].won?.toLocaleString(undefined, {maximumFractionDigits:2})
                            : "0"}
                          {/* <b>Won</b> : {row.won !== null ? row.won : "null"} */}
                          &nbsp;,&nbsp;&nbsp;
                          <b>Lost</b>{" "}
                          {selectedrows.membersObj[row.id].lost !== null
                            ? selectedrows.membersObj[row.id].lost?.toLocaleString(undefined, {maximumFractionDigits:2})
                            : "0"}
                        </p>
                        <p>
                          <b>Points</b> :{" "}
                          {selectedrows.membersObj[row.id].points !== null
                            ? selectedrows.membersObj[row.id].points?.toLocaleString(undefined, {maximumFractionDigits:2})
                            : "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={deletemodal} toggle={deletetoggle}>
        <ModalBody>
          <div>
            <div className="container">
              <div className="aling-center mt-2">{deletemsg}</div>
              <div className="aling-center mt-4">
                {Cdelete ? (
                  <Button
                    disabled={Cisloading}
                    className="btn-danger"
                    onClick={() => forcedeleteGroup()}
                  >
                    {Cisloading ? (
                      <Loader type="Grid" color="#fff" height={20} width={20} />
                    ) : (
                      "Delete Anyways"
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={Cisloading}
                    className="btn-danger"
                    onClick={() => deleteGroup()}
                  >
                    {Cisloading ? (
                      <Loader type="Grid" color="#fff" height={20} width={20} />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                )}
                <Button onClick={() => deletetoggle()}>Cancel</Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={newmodal} toggle={newtoggle}>
        <ModalBody>
          {Cisloading ? (
            <div className="aling-items-center">
              <Loader type="Grid" color="#8DBF26" height={60} width={60} />
            </div>
          ) : (
            <>
              {betData?.list?.length !== 0 ? (
                <div>
                  {betData?.list?.map((row) => (
                    <div className="container">
                      <div className="underline row">
                        <div className=" mt-2 col-md-6">
                          <b>Bet</b>
                          <div className="mt-2">
                            <p>
                              <b>Title</b> : {row.title}
                            </p>
                            <p>
                              <b>Created On Date</b> :{" "}
                              {moment(new Date(row.createdOnDate)).format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                            <p>
                              <b>Created By</b> : {row.createdBy}
                            </p>
                            <p>
                              <b>Expiration</b> :{" "}
                              {moment(new Date(row.expiration)).format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 col-md-6">
                          <b>&nbsp;</b>
                          <div className="mt-2">
                            <p>
                              <b>Type</b> : {row.type}
                            </p>
                            <p>
                              <b>Status</b> : {row.status}
                            </p>
                            <p>
                              <b>Points</b> :{" "}
                              {row.points !== null ? row.points.toLocaleString(undefined, {maximumFractionDigits:2}) : "null"}
                            </p>
                          </div>
                        </div>
                        <div className=" mt-2 col-md-12">
                          <p>
                            <b>Description</b> : {row.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h1>No Bets Found</h1>
                </div>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="head-search">
                  <div className="mt-2">
                    <h3 className="mb-0">All Groups</h3>
                  </div>
                  <div>
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search"
                          type="text"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      Title
                      <span
                        className="ml-2"
                        onClick={() => Togglearrow("title")}
                      >
                        {arrowToggle ? (
                          <i className="fas fa-arrow-up arrow" />
                        ) : (
                          <i className="fas fa-arrow-down arrow" />
                        )}
                      </span>
                    </th>

                    <th scope="col">
                      Created On
                      <span
                        className="ml-2"
                        onClick={() => Togglearrowdate("date")}
                      >
                        {lnamearrowDate ? (
                          <i className="fas fa-arrow-up arrow" />
                        ) : (
                          <i className="fas fa-arrow-down arrow" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="text-center">
                      Total Members
                      <span
                        className="ml-2"
                        onClick={() => TogglearrowMember("members")}
                      >
                        {arrowToggleTMember ? (
                          <i className="fas fa-arrow-up arrow" />
                        ) : (
                          <i className="fas fa-arrow-down arrow" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="ml-10x">
                      Members
                    </th>
                    <th scope="col" className="ml-10x">
                      Bets
                    </th>
                    <th className="text-center" scope="col">
                      Download
                    </th>
                    <th className="text-center" scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                {isloading ? (
                  <tbody>
                    <td colSpan="6">
                      <div className="aling-items-center">
                        <Loader
                          type="Grid"
                          color="#8DBF26"
                          height={60}
                          width={60}
                        />
                      </div>
                    </td>
                  </tbody>
                ) : (
                  <tbody>
                    {currentPages?.map((row) => (
                      <tr key={row.id}>
                        <th scope="row">
                          <Media>
                            <span className="mb-0 text-sm">
                              {row.title.length > MAX_LENGTH ? (
                                <>{`${row.title.substring(
                                  0,
                                  MAX_LENGTH
                                )}...`}</>
                              ) : (
                                <>{row.title}</>
                              )}
                            </span>
                          </Media>
                        </th>
                        <td>
                          {moment(new Date(row.createdOnDate)).format(
                            "MM-DD-YYYY"
                          )}
                        </td>
                        <td className="text-center">{row?.members?.length}</td>
                        <td>
                          <Button onClick={() => toggle(row)}>
                            View Members
                          </Button>
                        </td>
                        <td>
                          <Button onClick={() => newtoggle(row.id)}>
                            View Bets
                          </Button>
                        </td>
                        <td className="text-center">
                          <GroupExport data={[row]} />
                        </td>
                        <td className="text-center">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => deletetoggle(row.id)}
                              >
                                Delete Group
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <div className="footer-div">
                    <div>
                      <div>
                        Page: <b>{currentPage}</b>
                      </div>
                      <div>
                        <label>User per page :</label>
                        <Dropdown
                          className="dropdown-user"
                          isOpen={perpage}
                          toggle={() => setPerpage(!perpage)}
                        >
                          <DropdownToggle caret>{dataperPage}</DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => setDataperPage(10)}>
                              10
                            </DropdownItem>
                            <DropdownItem onClick={() => setDataperPage(50)}>
                              50
                            </DropdownItem>
                            <DropdownItem onClick={() => setDataperPage(100)}>
                              100
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {pagesNumbers?.map((number) => (
                        <PaginationItem
                          key={number}
                          className={currentPage === number ? "active" : ""}
                        >
                          <PaginationLink
                            href="#"
                            onClick={() => changePage(number)}
                          >
                            {number}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                    </Pagination>
                  </div>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Groups;
