import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import "../assets/css/style.css";
import logobackyard from "../assets/img/brand/logobat.png";
import BackyardBet from "../assets/img/brand/BackyardBet.png";
import PublicPrivacyPolicy from "views/privacy policy/publicPrivacyPolicy";
import PublicTerms from "views/termsAndCondition/publicTerms";

import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    if (
      location?.pathname === "/auth/privacypolicy" ||
      location?.pathname === "/auth/termsandcondition"
    ) {
      document.body.classList.add("bgWhite");
      return () => {
        document.body.classList.remove("bgWhite");
      };
    } else {
      document.body.classList.add("new-bg");
      return () => {
        document.body.classList.remove("new-bg");
      };
    }
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {location?.pathname === "/auth/privacypolicy" ||
      location?.pathname === "/auth/termsandcondition" ? (
        <div className="main-content" ref={mainContent}>
          <div className="colorWhite py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7 mt-minus">
                <Row className="justify-content-center">
                  {location?.pathname === "/auth/privacypolicy" ||
                  location?.pathname === "/auth/termsandcondition" ? (
                    <Col lg="5" md="6">
                      <img alt={"Logo"} className="logo" src={BackyardBet} />
                    </Col>
                  ) : (
                    <Col lg="5" md="6">
                      <img alt={"Logo"} className="logo" src={logobackyard} />
                    </Col>
                  )}
                </Row>
              </div>
            </Container>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              {location?.pathname === "/auth/privacypolicy" ? (
                <PublicPrivacyPolicy />
              ) : (
                <PublicTerms />
              )}
            </Row>
          </Container>
        </div>
      ) : (
        <div className="main-content" ref={mainContent}>
          <div className="header  py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7 mt-minus">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img alt={"Logo"} className="logo" src={logobackyard} />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon fill="#000" points="2560 0 2560 100 0 100" />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Auth;
