import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  InputGroup,
  Input,
  InputGroupText,
  InputGroupAddon,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Button,
  Label,
  Container,
  Form,
  Dropdown,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../../state/index";
import Loader from "react-loader-spinner";
import moment from "moment";
import UserExport from "components/helper/userExport";
import { useToasts } from "react-toast-notifications";
import { SortArray } from "../../components/helper/filter";

const Users = () => {
  const [arrowToggle, setArrowToggle] = useState(true);
  const [lnamearrowToggle, setLnamearrowToggle] = useState(true);
  const [lnamearrowDate, setLnamearrowDate] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [userId, setUserId] = useState("");
  const [modal, setModal] = useState(false);
  const [perpage, setPerpage] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [Rowid, setRowid] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [dataperPage, setDataperPage] = useState(10);
  const [Cdelete, setCdelete] = useState(false);
  const [deletemsg, setDeletemsg] = useState(
    "Are you sure you want to delete this User?"
  );

  useEffect(() => {
    GET_USERS();
  }, []);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const isloading = useSelector((state) => state.allAuth.headerLoading);
  const isloadingsce = useSelector((state) => state.allAuth.isLoading);
  const userDetails = useSelector((state) => state.allAuth.userDetails);
  const userData = useSelector((state) => state.allAuth.userData);

  const {
    GET_USERS,
    PUT_UPDATE_USER,
    GET_USER_BY_ID,
    DELETE_USER,
    FORCE_DELETE_USER,
  } = bindActionCreators(actionsCreators, dispatch);

  var currencyFormatter = require("currency-formatter");

  const changePage = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  const MAX_LENGTH = 50;

  // toggler icon for sort
  const ArrowToggler = (type) => {
    switch (type) {
      case "firstName":
        if (arrowToggle) {
          SortArray(userData?.data?.list, type, "acs");
          setArrowToggle(!arrowToggle);
        } else {
          SortArray(userData?.data?.list, type, "dsc");
          setArrowToggle(!arrowToggle);
        }
        break;
      case "lastName":
        if (lnamearrowToggle) {
          SortArray(userData?.data?.list, type, "acs");
          setLnamearrowToggle(!lnamearrowToggle);
        } else {
          SortArray(userData?.data?.list, type, "dsc");
          setLnamearrowToggle(!lnamearrowToggle);
        }
        break;
      case "date":
        if (lnamearrowDate) {
          SortArray(userData?.data?.list, type, "date");
          setLnamearrowDate(!lnamearrowDate);
        } else {
          SortArray(userData?.data?.list, type, "newdate");
          setLnamearrowDate(!lnamearrowDate);
        }
        break;
      default:
        break;
    }
  };

  const toggle = (row) => {
    setFname(row?.firstName);
    setLname(row?.lastName);
    // setIsBank(row?.isBank);
    setUserId(row?.id);
    setModal(!modal);
  };

  // toggle with api call
  const detailstoggle = async (row) => {
    setDetailsModal(!detailsModal);
    try {
      const res = await GET_USER_BY_ID(row?.id);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const deleteToggler = (row) => {
    setDeleteModal(!deletemodal);
    setRowid(row);
  };

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const filteredPersons = userData?.data?.list.filter((person) => {
    return (
      person.firstName.toLowerCase().includes(searchField.toLowerCase()) ||
      person.lastName.toLowerCase().includes(searchField.toLowerCase())
    );
  });

  const indexOfLastData = currentPage * dataperPage;
  const indexOfFirstData = indexOfLastData - dataperPage;
  const currentPages = filteredPersons?.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const pagesNumbers = [];

  for (
    let i = 1;
    i <= Math.ceil(userData?.data?.list?.length / dataperPage);
    i++
  ) {
    pagesNumbers.push(i);
  }
  const update_user = async (e) => {
    e.preventDefault();
    if (fname !== "" && lname !== "") {
      let data = {
        firstName: fname,
        lastName: lname,
        id: userId,
      };
      try {
        const res = await PUT_UPDATE_USER(data);
        if (res?.data?.isSuccess) {
          addToast(res?.data?.message, {
            appearance: "success",
            autoDismiss: true,
          });
          setModal(!modal);
          GET_USERS();
        } else {
          addToast(res?.message, {
            appearance: "error",
            autoDismiss: true,
          });
          setModal(!modal);
          GET_USERS();
        }
      } catch (error) {
        addToast("Something Went Wrong, Please Try Again", {
          appearance: "error",
          autoDismiss: true,
        });
        setModal(!modal);
        GET_USERS();
      }
    } else {
      addToast("Fill All The Feilds", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  };

  const deleteUser = async () => {
    try {
      const res = await DELETE_USER(Rowid);
      if (res?.data?.isSuccess) {
        addToast(res?.data?.message, {
          appearance: "success",
          autoDismiss: true,
        });
        GET_USERS();
        setDeleteModal(false);
      } else {
        if (res?.data?.data?.inBetProgress) {
          setDeletemsg(res?.data?.message);
          setCdelete(true);
        } else {
          addToast(res?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    } catch (error) {}
  };

  const forcedeleteUser = async () => {
    try {
      const res = await FORCE_DELETE_USER(Rowid);
      if (res?.data?.isSuccess) {
        addToast(res?.data?.message, {
          appearance: "success",
          autoDismiss: true,
        });
        GET_USERS();
        setDeletemsg("Are you sure you want to delete this User?");
        setDeleteModal(false);
        setCdelete(false);
      } else {
        addToast(res?.data?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(error?.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            <Label for="exampleEmail" className="text-center">
              <b>Edit User</b>
            </Label>
            <div>
              <Form onSubmit={update_user}>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0  mt-2">
                  <Label className="mr-sm-2">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0 mt-2">
                  <Label className="mr-sm-2">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                  />
                </FormGroup>
                <div className="text-center">
                  <Button disabled={isloadingsce} className="btn-success mt-4">
                    {isloadingsce ? (
                      <Loader
                        type="Grid"
                        color="#ffffff"
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                  <Button className="mt-4" onClick={() => setModal(!modal)}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      </>
      <>
        <Modal isOpen={deletemodal} toggle={deleteToggler}>
          <ModalBody>
            <div className="aling-center mt-2">{deletemsg}</div>
            <div className="aling-center mt-4">
              {Cdelete ? (
                <Button
                  disabled={isloadingsce}
                  className="btn-danger"
                  onClick={() => forcedeleteUser()}
                >
                  {isloadingsce ? (
                    <Loader type="Grid" color="#fff" height={20} width={20} />
                  ) : (
                    "Delete Anyways"
                  )}
                </Button>
              ) : (
                <Button
                  disabled={isloadingsce}
                  className="btn-danger"
                  onClick={() => deleteUser()}
                >
                  {isloadingsce ? (
                    <Loader type="Grid" color="#fff" height={20} width={20} />
                  ) : (
                    "Delete"
                  )}
                </Button>
              )}

              <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
            </div>
          </ModalBody>
        </Modal>
      </>
      <>
        <Modal size="lg" isOpen={detailsModal} toggle={detailstoggle}>
          <ModalBody>
            {isloadingsce ? (
              <div className="aling-items-center">
                <Loader type="Grid" color="#8DBF26" height={50} width={50} />
              </div>
            ) : (
              <div className="mt-3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-7">
                      <b>User Details</b>
                      <div className="mt-4">
                        <p>
                          <b>Full Name</b> : {userDetails?.data?.fullName}
                        </p>

                        <p>
                          <b>Bets Won</b> : {userDetails?.data?.won.toLocaleString(undefined, {maximumFractionDigits:2})} 
                        </p>
                        <p>
                          <b>Bets lost</b> : {userDetails?.data?.lost.toLocaleString(undefined, {maximumFractionDigits:2})}
                        </p>
                        <p>
                          <b>Total Points</b> : {userDetails?.data?.points.toLocaleString(undefined, {maximumFractionDigits:2})}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 col-md-5">
                      <b>&nbsp;</b>
                      <div>
                        <p>
                          <b>Status</b> : {userDetails?.data?.status}
                        </p>
                        {/* <p>
                          <b>Role</b> : {userDetails?.data?.role}
                        </p> */}
                        <p>
                          <b>Payment Method Enable</b>:{" "}
                          {userDetails?.data?.isBank ? "Yes" : "No"}
                        </p>
                        <p>
                          <b>Total Amount Won</b> :{" "}
                          {currencyFormatter.format(
                            userDetails?.data?.wonAmount,
                            { code: "USD" }
                          )}
                        </p>
                        <p>
                          <b>Total Amount Lost</b> :{" "}
                          {currencyFormatter.format(
                            userDetails?.data?.lostAmount,
                            { code: "USD" }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mb-2">
                  <Button
                    className="mt-4"
                    onClick={() => setDetailsModal(!detailsModal)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>
      </>
      <Header />
      {/* Page content */}

      <Container className="mt--7" fluid>
        <div className="userexport-btn">
          <UserExport data={userData?.data?.list} />
        </div>

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="head-search">
                  <div className="mt-2">
                    <h3 className="mb-0">All Users</h3>
                  </div>
                  <div>
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search"
                          type="text"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      firstName
                      <span
                        className="ml-2"
                        onClick={() => ArrowToggler("firstName")}
                      >
                        {arrowToggle ? (
                          <i className="fas fa-arrow-up arrow" />
                        ) : (
                          <i className="fas fa-arrow-down arrow" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="text-center">
                      lastName
                      <span
                        className="ml-2"
                        onClick={() => ArrowToggler("lastName")}
                      >
                        {lnamearrowToggle ? (
                          <i className="fas fa-arrow-up arrow" />
                        ) : (
                          <i className="fas fa-arrow-down arrow" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="text-center">
                      Created On Date
                      <span
                        className="ml-2"
                        onClick={() => ArrowToggler("date")}
                      >
                        {lnamearrowDate ? (
                          <i className="fas fa-arrow-up arrow" />
                        ) : (
                          <i className="fas fa-arrow-down arrow" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="text-center">
                      User Details
                    </th>

                    <th scope="col" className="text-center">
                      Payment Method Enable
                    </th>
                    {/* <th scope="col">Profile Picture</th> */}
                    <th scope="col" className="headtr">
                      Actions
                    </th>
                  </tr>
                </thead>
                {isloading ? (
                  <tbody>
                    <td colSpan="6">
                      <div className="aling-items-center">
                        <Loader
                          type="Grid"
                          color="#8DBF26"
                          height={60}
                          width={60}
                        />
                      </div>
                    </td>
                  </tbody>
                ) : (
                  <tbody>
                    {currentPages?.map((row) => (
                      <tr key={row.id}>
                        <td>
                          {row.firstName.length > MAX_LENGTH ? (
                            <>{`${row.firstName.substring(
                              0,
                              MAX_LENGTH
                            )}...`}</>
                          ) : (
                            <>{row.firstName}</>
                          )}
                        </td>
                        <td className="text-center">{row.lastName}</td>
                        <td className="text-center">
                          {moment(new Date(row.createdOnDate)).format(
                            "MM-DD-YYYY"
                          )}
                        </td>

                        <td className="text-center">
                          <Button
                            className="mt-1"
                            onClick={() => detailstoggle(row)}
                          >
                            User Details
                          </Button>
                        </td>
                        <td className="text-center">
                          {row.isBank ? (
                            <i className="ni ni-check-bold text-green icon-style" />
                          ) : (
                            <i className="ni ni-fat-remove text-red" />
                          )}
                        </td>
                        <td className="text-center">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem onClick={() => toggle(row)}>
                                Edit User
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => deleteToggler(row.id)}
                              >
                                Delete User
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <div className="footer-div">
                    <div>
                      <div>
                        Page: <b>{currentPage}</b>
                      </div>
                      <div>
                        <label>User per page :</label>
                        <Dropdown
                          className="dropdown-user"
                          isOpen={perpage}
                          toggle={() => setPerpage(!perpage)}
                        >
                          <DropdownToggle caret>{dataperPage}</DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => setDataperPage(10)}>
                              10
                            </DropdownItem>
                            <DropdownItem onClick={() => setDataperPage(50)}>
                              50
                            </DropdownItem>
                            <DropdownItem onClick={() => setDataperPage(100)}>
                              100
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {/* <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem> */}
                      {pagesNumbers?.map((number) => (
                        <PaginationItem
                          key={number}
                          className={currentPage === number ? "active" : ""}
                        >
                          <PaginationLink
                            href="#"
                            onClick={() => changePage(number)}
                          >
                            {number}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      {/* <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem> */}
                      {/* <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem> */}
                    </Pagination>
                  </div>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
